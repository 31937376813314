import React from "react";
import { Switch, Text } from "react-native";
import { View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";

const AddOns = ({ tenant }) => {
    const Tenant = tenant.data.tenant;

    const [data, setData] = React.useState({
        archive: Tenant.archive,
        voice: Tenant.voice,
        record: Tenant.record,
        transcribe: Tenant.transcribe
    })

    const handleSave = async () => {
        const body = {
            ...Tenant,
            action: 'update_tenant',
            tenant: Tenant.id,
            ...data
        }
        const result = await RequestMaker(body);
        if (result.data.success) {
            
            tenant.rerefetch();
        }
    }

    React.useEffect(() => {
        setData({
            archive: Tenant.archive,
            voice: Tenant.voice,
            record: Tenant.record,
            transcribe: Tenant.transcribe
        })
    }, [Tenant])

    return (
        <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>
                You can override how the account has add-ons configured. Note that turning on an add-on without having a payment method set up may cause the account to go into default when we process payment for the account.
            </Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>
                Archive Enabled
            </Text>
            <Switch
                value={data.archive}
                onValueChange={e => setData({ ...data, archive: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>
                Voice Enabled
            </Text>
            <Switch
                value={data.voice}
                onValueChange={e => setData({ ...data, voice: e })}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>
                Record Enabled
            </Text>
            <Switch
                value={data.record}
                onValueChange={e => setData({ ...data, record: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>
                Transcribe Enabled
            </Text>
            <Switch
                value={data.transcribe}
                onValueChange={e => setData({ ...data, transcribe: e })}
            />

            <CustomButton onPress={handleSave} width={'20%'} style={{ alignSelf: 'flex-end', marginTop: 'auto' }} title='Save' />
        </View>
    )
}

export default AddOns;