import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FlatList,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Search from '../../Components/Search';
import Loader from '../../Components/Loader';
import { clearUnreadMessagesByID, loadRecentMessagesThunk, setActiveChat } from '../../Redux/recent_message';
import { loadAllContactsThunk } from '../../Redux/contacts';
import { loadRecentMediaThunk } from '../../Redux/recent_media';
import { loadSavedMessagesThunk } from '../../Redux/saved_messages';
import { loadAccountStatThunk } from '../../Redux/account_stat';
import { loadAccountHealthThunk } from '../../Redux/account_health';
import {
  loadIntegrationProviderThuunk,
  loadIntegrationsThuunk,
} from '../../Redux/integrations';
import {
  loadGroupAddOnsThuunk,
  loadBillingDetailsThuunk,
} from '../../Redux/add_ons';
import { loadAllDistributionsThunk } from '../../Redux/distributions';
import { loadDuplicateNumbersThunk } from '../../Redux/duplicate_members';
import { setProfile } from '../../Redux';
import Chat from '../Chat/chat_side';

import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import MessagePreview from './MessagePreview';
import { Bubbles } from '../Authentication/Login/Bubbles';
import firebase from '../../../firebase_config';
import TabFocusChecker from './TabFocusChecker';
import { TermsOfUseModal } from './TermsOfUseModal';
import StartConversation from './StartConversation';
import { useFocusEffect } from '@react-navigation/native';
import SimpleDropdown from '../../Components/SimpleDropdown';
import { Menu } from 'native-base';
import { Icon } from 'react-native-elements';
import CustomButton from '../../Components/Button/index.web';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

const Messages = ({ navigation, route }) => {
  const recent_messages = useSelector((state) => state.recent_messages).data;
  const { newMessage, badgeNums } = useSelector(
    (state) => state.receivedNewMessage
  );
  const [search, setSearch] = React.useState('');
  const affiliate = useSelector((state) => state.User.profile.affiliate);
  const dispatch = useDispatch();
  const [selectedChat, setSelectedChat] = React.useState(null);
  const [selectedChatMessages, setSelectedChatMessages] = React.useState([]);
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedDL, setSelectedDL] = useState(null);
  const { tenant, tous } = useSelector((state) => state.User.profile);
  const loadRecentMessages = () => dispatch(loadRecentMessagesThunk());
  const messageScreenIsActive = React.useRef();
  const [filter, setFilter] = React.useState('all');
  const [typedmessage, setTypedmessage] = React.useState('');
  const { isWebCompact } = useResponsiveLayout();
  const [messageDraftsCache, setMessageDraftsCache] = React.useState({});
  const hasGroupMessaging = tenant?.groupTexting;

  React.useEffect(() => {
    if (newMessage) {
      setTimeout(() => {
        loadPreviewsAndCurrentChatMessages();
      }, 500)
    }
  }, [newMessage]);


  React.useEffect(() => {

    dispatch(loadAccountStatThunk());
    dispatch(loadAllContactsThunk());
    dispatch(loadRecentMediaThunk());
    dispatch(loadSavedMessagesThunk());
    dispatch(loadIntegrationProviderThuunk());
    dispatch(loadIntegrationsThuunk());
    dispatch(loadGroupAddOnsThuunk());
    dispatch(loadBillingDetailsThuunk());
    dispatch(loadAllDistributionsThunk());

    if (typeof tous === 'undefined') {
      authorize();
    } else if (tous?.length > 0) {
      setVisible(true);
    }

    return () => {
      navigation.setParams({ template_message: null, memberData: null, unread: false, bookmark: null });
    };

  }, [])

  React.useEffect(() => {

    if (tenant?.id) {
      dispatch(loadAccountHealthThunk(tenant.id));
      dispatch(loadDuplicateNumbersThunk(tenant.id));
    }

    if (tenant?.name) {
      loadRecentMessages();
      setSelectedChat(null);
    }
  }, [tenant?.name, tenant?.id])


  React.useEffect(() => {
    if (route.params?.unread == true) {
      setFilter('unread');
    }

    

    if (route.params?.memberData) {
      loadSelectedMessageDetails(route.params.memberData.id);
      setSelectedDL(null);
    } else if (route.params?.dl) {
      setSelectedDL(route.params.dl);
      setSelectedChatMessages(null)
      setSelectedChat(null);
    }

    if (route.params?.template_message) {
      setTypedmessage(route.params.template_message);
    } else if (route.params?.bookmark){
      let parsedMessage = '';
      if(route.params?.message) parsedMessage = `${route.params.message} `;
      parsedMessage = parsedMessage + route.params.bookmark
      setTypedmessage(parsedMessage);
    }

  }, [route.params]);

  React.useEffect(() => {
    let timer;

    const focus = navigation.addListener('focus', () => {
      messageScreenIsActive.current = true;
      timer = null;
      if (Notification.permission !== 'granted' || !firebase.messaging.isSupported()) {
        timer = setInterval(() => {
          loadRecentMessages()
        }, 120000)
      }
    })

    const blur = navigation.addListener('blur', () => {
      dispatch(setActiveChat({ id: null }));

      navigation.setParams({ template_message: null, memberData: null, unread: false });

      messageScreenIsActive.current = false;
      if (timer) {
        clearInterval(timer);
      }
    })
  }, [navigation])

  useFocusEffect(
    React.useCallback(() => {
      loadPreviewsAndCurrentChatMessages()
    }, [])
  )


  const RenderList = (i) => {
    let item = i;
    const unreadMessageCount = i.id === selectedChat?.id ? 0 : i.unreadmessages;

    return (
      <MessagePreview
        unreadMessages={unreadMessageCount}
        focused={i.id === selectedChat?.id}
        i={item}
        onPress={() => {
          if (selectedChat?.id !== i.id) {

            dispatch(setActiveChat({ id: i.id }));
            dispatch(clearUnreadMessagesByID({ id: i.id }));
            
            if(selectedChat?.id) {
              setMessageDraftsCache({...messageDraftsCache, [selectedChat?.id]: typedmessage});
            }

            loadSelectedMessageDetails(i.id);

            if(messageDraftsCache[i.id]){
              setTypedmessage(messageDraftsCache[i.id]);
            } else {
              setTypedmessage('');
            }

          }
        }}
      />
    );
  };

  const authorize = async () => {
    try {
      setLoader(true);
      const fcmToken = await getData('fcmToken');
      const body = {
        action: 'authorize',
        pushKey: fcmToken,
      };
      let { data } = await RequestMaker(body);
      setLoader(false);
      dispatch(setProfile(data));
      if (data?.tous?.length > 0) {
        setVisible(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };


  const loadMemberDetails = async (id) => {
    try {
      const body = {
        action: 'get_member',
        id: id,
      };
      let result = await RequestMaker(body);
      if (result.status === 200 && result.data.id) {
        return result.data;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  const loadContactMessages = async (id, offset = 0, limit = 25) => {
    try {
      const body = {
        action: 'get_messages',
        member: id,
        offset: offset,
        limit: limit,
      };
      const result = await RequestMaker(body);
      if (result.data.success && result.data.communications && Array.isArray(result.data.communications)) {

        const communications = result.data.communications;

        if(result.data.Contacts){

          communications.forEach((e,i) => {
            let fromNumber = e.from_number;

            if(fromNumber.includes('+1')){
              fromNumber = fromNumber.replace('+1', '');
            }

            const contact = result.data.Contacts.find(x => x.phone1 === fromNumber);

            if(contact){
              communications[i].groupChatIdentifier = contact.fname + ' ' + contact.lname;
            } else {
              communications[i].groupChatIdentifier = fromNumber;
            }

          })
          
        }
        return communications;
      }
    } catch (err) {
      console.log(err);
    }
    return [];
  };

  const loadSelectedMessageDetails = async (id) => {
    setLoader(true)
    const [member, messages] = await Promise.all([
      loadMemberDetails(id),
      loadContactMessages(id),
    ]);
    setLoader(false)
    setSelectedDL(null);
    if (messages) {
      setSelectedChatMessages(messages);
    }
    if (member) {
      setSelectedChat(member);
    }

  }

  const loadPreviewsAndCurrentChatMessages = async () => {
    if(messageScreenIsActive.current){
      loadRecentMessages();
    }
    if (selectedChat?.id) {
      loadSelectedMessageDetails(selectedChat.id);
    }
  }

  const messageTypeFilter = x => {
    if(filter === 'all') return true;
    if(filter === 'sms' && (x.messageType === 'sms' || x.messageType === 'mms')) return true;
    if(filter === 'call' && x.messageType === 'call') return true;
    if(filter === 'unread' && x.unreadmessages > 0) return true;
  }

  const searchFilter = x => {
    if(search === '') return true;
    if(`${x.fname + ' ' + x.lname}`.toLowerCase().includes(search.toLowerCase())) return true;
  }

  const handleLoadMoreMessages = async () => {
    if (selectedChat?.id) {
      const offset = selectedChatMessages.length;
      const messages = await loadContactMessages(selectedChat.id, offset);
      if (messages.length > 0) {
        setSelectedChatMessages([...messages, ...selectedChatMessages]);
      }
    }
  }

  const showSidebar = !isWebCompact  || (isWebCompact && !selectedChat);
  const showContent = !isWebCompact  || (isWebCompact && selectedChat);
  return (
    <>
      <Wrapper>
        {loader && <Loader />}
        {
        showSidebar &&
        <SideBar>
          <Search
            onChangeText={t => setSearch(t)}
            placeholder="Filter by contact name"
            actionButtonTitle={'Message'}
            onPressActionButton={() => setSelectedChat('create')}
            actionButton
            actionButtonComponent={
              hasGroupMessaging ?
              <Menu placement='bottom' trigger={triggerProps => {
                return (
                  <TouchableOpacity {...triggerProps}>
                  <CustomButton
                  {...triggerProps}
                  icon={<Icon name='plus' size={18} color='white' type='feather' />}
                  title={'Message'}
                  width={'none'}
                  textStyle={{ textTransform: 'none', fontSize: 16 }}
                  style={{ borderRadius: 7, gap: 7, fontWeight: 100,paddingHorizontal:10 }} />
                  </TouchableOpacity>
                )
              }}
              >
                <Menu.Item
                style={{flexDirection:'row',alignItems:'center',gap:5}}
                  onPress={() => { setSelectedChat('create') }}
                >
                    <Icon name='message1' type='ant-design' />
                    <Text style={{ marginLeft: 5 }}>New Message</Text>
                </Menu.Item>

                <Menu.Item
                style={{flexDirection:'row',alignItems:'center',gap:5}}
                  onPress={() => { setSelectedChat('createGroup') }}
                >
                    <Icon name='group' type='material-icons' />
                    <Text style={{ marginLeft: 5 }}>Group Message</Text>
                </Menu.Item>

              </Menu> 
              :
              null
          }
          />

          <SimpleDropdown 
          
          value={filter}
          onChange={val => setFilter(val)}
          style={{marginHorizontal:10}}
          data={[
            {
            label: 'Messages + Calls',
            value: 'all'
            },
            {
            label: 'Messages',
            value: 'sms'
            },
            {
            label: 'Calls',
            value: 'call'
            },
            {
              label: 'Unread Messages', 
              value: 'unread'
            }
          ]}/>
          <FlatList
            ItemSeparatorComponent={() => {
              return (
                <View
                  style={{
                    height: 1,
                    backgroundColor: '#DEDCD9',
                    width: '95%',
                    alignSelf: 'center',
                  }}></View>
              );
            }}
            ListEmptyComponent={
              <View style={{ alignItems: 'center', marginTop: 20 }}>
                <Text>No Messages</Text>
              </View>
            }
            contentContainerStyle={{paddingTop:10}}
            keyExtractor={(item, index) => item.id + '' + index}
            data={
              recent_messages.filter(messageTypeFilter).filter(searchFilter)
            }
            renderItem={({ item }) => {
              return RenderList(item);
            }}
          />

        </SideBar>
        }
        {
          showContent && 
        <Content>
          {
          selectedChat === 'create' || selectedChat === 'createGroup' ?
            <StartConversation
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
            onSelectDistributionList={(dl) => {
              setSelectedChatMessages([]);
              setSelectedChat(null);
              setSelectedDL(dl);
            }}
            onSelectContact={(member) => {
              loadSelectedMessageDetails(member.id);
            }}
            />
          :
          selectedChat || selectedDL ?
            <Chat
              handleLoadMoreMessages={handleLoadMoreMessages}
              typedmessage={typedmessage}
              setTypedmessage={setTypedmessage}
              setSelectedChat={setSelectedChat}
              navigation={navigation}
              memberData={selectedChat}
              messages={selectedChatMessages}
              loadPreviewsAndCurrentChatMessages={loadPreviewsAndCurrentChatMessages}
              loadContactMessages={loadContactMessages}
              dl={selectedDL}
            />
            :
            <Bubbles>
              <Text style={{ color: '#116AB4', fontSize: 32, width: '70%' }}>
                Select a chat to start messaging or start a new conversation.
              </Text>
            </Bubbles>
          }
        </Content> 
        }


        {visible &&
          <TermsOfUseModal setVisible={setVisible} visible={visible} setLoader={setLoader} />
        }

        <TabFocusChecker onTabRefocused={loadPreviewsAndCurrentChatMessages} />

      </Wrapper>
    </>
  );
};

export default Messages;
