import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const recentMessageSlice = createSlice({
  name: 'recent_message',
  initialState: {
    data: [],
    loading: false,
    activeChatId: null,
  },
  reducers: {
    setRecentMessage: (state, action) => {
      const { data } = action.payload;
      const { activeChatId } = state;

      // Update the unreadmessages property to 0 for the active chat
      if (activeChatId !== null) {
        const updatedData = data.map(chat => {
          if (chat.id === activeChatId) {
            return { ...chat, unreadmessages: 0 };
          }
          return chat;
        });

        state.data = updatedData;
      } else {
        state.data = data;
      }
    },
    clearUnreadMessagesByID: (state, action) => {
      const messageToClear = state.data.find(e => e.id === action.payload.id);

      if (messageToClear) {
        messageToClear.unreadmessages = 0;
      }    
    },
    setActiveChat: (state, action) => {
      return {
        ...state,
        activeChatId: action.payload.id,
      }
    }
  },
});

export const { setRecentMessage, clearUnreadMessagesByID, setActiveChat } = recentMessageSlice.actions;

export const loadRecentMessagesThunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_recent_member_message',
        days: 90,
        offset: 0,
        filter: "all",
        limit: 100,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);

      const noDuplicateData = [];

      if (result.data.members.length) {
        result.data.members.forEach(e => {
          const index = noDuplicateData.findIndex(ee => ee.mobile === e.mobile && ee.fname === e.fname && ee.lname === e.lname && ee.message === e.message);
          if (index === -1) {
            noDuplicateData.push(e);
          }
        })
      }

      dispatch(setRecentMessage({ data: noDuplicateData }));

    } catch (err) {
      console.log(err);
    }
  };
};

export default recentMessageSlice.reducer;
