import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import getData from '../AsyncUtils/GetData';
import { setProfile, setToken } from '../Redux';
import Login from '../Screens/Authentication/Login';
import Splash from '../Screens/Splash';
import VerifyEmail from '../Screens/Authentication/VerifyEmail';
import Drawer from '../Drawer';
import ForgertPassword from '../Screens/Authentication/forget_password';
import Verification from '../Screens/Authentication/Signup/verification';
import CompanyDetail from '../Screens/Authentication/Signup/CompanyDetail';
import GroupSetup from '../Screens/Authentication/Signup/GroupSetup';
import EmailPassword from '../Screens/Authentication/Signup/EmailPassword';
import SearchMessages from '../Screens/Messages/SearchMessages';
import OAuthView from '../Screens/Settings/Integrations/ui/OAuthView';
import SSOScreen from '../Screens/Authentication/SSOScreen';
import MobileAuth from '../Screens/Authentication/Mobile';
import ContentScreen from '../Screens/Chat/ContentScreen';
import SignupWizard from '../Screens/SignupWizard';
import AppOptions from '../Screens/Profile/AppOptions';
import PositiveConsent from '../Screens/Profile/PositiveConsent';
import Integrations from '../Screens/Settings/Integrations';
import Support from '../Screens/Support/index';
import { SocketProvider } from '../Components/Ten4/Ten4SocketProvider';
import LOAForm from '../Screens/SignupWizard/MyRepChatNum/LOAForm';
import GroupAdminDrawer from '../GroupAdmin/GroupAdminDrawer';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';
import storeData from '../AsyncUtils/StoreData';
import keyword from '../Constants/Keywords';
import Alert from 'Alert';
import axios from 'axios';
import { analytics } from '../../App';
import SurveyModal from '../Components/Modals/SurveyModal';

const Stack = createStackNavigator();

const title = (text) => Platform.select({ web: `MyRepChat | ${text}`, default: text });

function StackScreens() {
  const { authenticated, profile } = useSelector((state) => state.User);
  

  return (
    <>
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {!authenticated ? (
        <>
          <Stack.Screen
            name="Login"
            component={Login}
            options={{ title: title('Login') }}
          />
          <Stack.Screen
            name="SSOScreen"
            component={SSOScreen}
            options={{ title: title('SSO') }}
          />
          <Stack.Screen
            name="GroupSetup"
            component={GroupSetup}
            options={{ title: title('Organization Setup') }}
          />
          <Stack.Screen
            name="VerifyEmail"
            component={VerifyEmail}
            options={{ title: title('VerifyEmail') }}
          />
          <Stack.Screen
            name="CompanyDetail"
            component={CompanyDetail}
            options={{ title: title('CompanyDetail') }}
          />
          <Stack.Screen
            name="Verification"
            component={Verification}
            options={{ title: title('Verification') }}
          />
          <Stack.Screen
            name="EmailPassword"
            component={EmailPassword}
            options={{ title: title('Email Password') }}
          />
          <Stack.Screen
            name="ForgetPassword"
            component={ForgertPassword}
            options={{ title: title('Forget Password') }}
          />
          <Stack.Screen
            name="MobileAuth"
            component={MobileAuth}
            options={{ title: title('Login') }}
          />
        </>
      ) : (
        <>
          {
          profile?.user?.permissions[0]?.label === 'System Administrator' || profile?.user?.permissions[0]?.label === 'Group Administrator' || profile?.user?.permissions[0]?.label === 'Organization Administrator' || profile?.user?.permissions[0]?.label === 'Reseller' ? 
          <Stack.Screen name="GroupAdminDrawer" component={GroupAdminDrawer} />
          :
          profile?.tenant?.tw_number ? (
            <Stack.Screen name="Drawer" component={Drawer} />
          ) : (
            <>
            <Stack.Screen name="SignupWizard" component={SignupWizard} />
            <Stack.Screen
            name="Integrations"
            component={Integrations}
            options={{ title: title('Integrations') }}
          />
          <Stack.Screen
            name="Support"
            component={Support}
            options={{ title: title('Support') }}
          />
          </>
    
          )}
        </>
      )}

    </Stack.Navigator>

    
    </>
  );
}

function SplashScreen() {
  const [splash, setSplash] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    authorize();
  },[]);

  const EXPIRATION = 1000 * 60 * 60 * 24 * 7; //7 days

  const apiAuthorize = async token => {
    const fcmToken = await getData('fcmToken');
    const body = {
      action: 'authorize',
      newToken: true,
      pushKey: fcmToken,
      expiration: EXPIRATION,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    return axios(Config);

  }

  const authorize = async () => {
    apiAuthorize()
    .then(result => {
      if (!result.data.success) {
        localStorage.clear();
        return;
      }
      if (result.status == 200) {
        if (result.data) {
          
            dispatch(setProfile(result.data));

            if (result.headers && result.headers['x-dart-token']) {
              storeData(
                keyword.user_auth,
                JSON.stringify({ username: result.data?.user?.email, password: '' })
              );
              dispatch(
                setToken({
                  authenticated: true,
                })
              );
            }
        
        }
      } else if (result.status == 401) {
        localStorage.clear();
        setSplash(false);
        return;
      } else {
        setSplash(false);
        Alert.alert(result.data.errorMessage);
      }

    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      setSplash(false);
    })
  };

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {splash && <Stack.Screen name="Splash" component={Splash} />}
      <Stack.Screen name="StackScreens" component={StackScreens} />
    </Stack.Navigator>
  );
}

function Router() {

  const linking = {
    config: {
      NotFound: "404",
      StackScreens: {
        screens: {
          Login: "Login",
          Drawer: "Home",
          GroupSetup: "Register/GroupCode",
          CompanyDetail: "Register/CompanyDetail",
          EmailPassword: "Register/EmailPassword",
          SignupWizard: "Register/SignupWizard",
          
        },
      },
    },
  }
  
  const navigationRef = React.useRef(null);
  const routeNameRef = React.useRef();
  
  return (
    <NavigationContainer 
    ref={navigationRef}
    onReady={() => {
      routeNameRef.current = navigationRef.current.getCurrentRoute().name;
    }}
    onStateChange={async () => {
      const previousRouteName = routeNameRef.current;
      const currentRouteName = navigationRef.current.getCurrentRoute().name;
      if (previousRouteName !== currentRouteName) {
        if (analytics) {
          analytics.logEvent('screen_view', {
            screen_name: currentRouteName,
          });
      }

      }
      routeNameRef.current = currentRouteName;
    }}>
        <SplashScreen />
      </NavigationContainer>
  );
}

export default Router;
