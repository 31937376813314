import End_Points from "../Constants/Api";

class IntegrationType {
  constructor(name, label) {
    this.name = name;
    this.label = label;
  }
}

class IntegrationProvider {
  constructor1(
    name,
    label,
    type,
    helpUrl,
    fullImageUrlPath,
    fullImageHeight,
    smallImageUrlPath,
    smallImageHeight
  ) {
    this.name = name;
    this.label = label;
    this.type = type;
    this.helpUrl = helpUrl;
    this.fullImageUrlPath = fullImageUrlPath;
    this.fullImageHeight = fullImageHeight;
    this.smallImageUrlPath = smallImageUrlPath;
    this.smallImageHeight = smallImageHeight;
    return this;
  }

  constructor2(name, label, type, helpUrl, fullImageUrlPath, fullImageHeight) {
    this.name = name;
    this.label = label;
    this.type = type;
    this.helpUrl = helpUrl;
    this.fullImageUrlPath = fullImageUrlPath;
    this.fullImageHeight = fullImageHeight;
    return this;
  }
  constructor3(
    name,
    label,
    attr,
    type,
    helpUrl,
    fullImageUrlPath,
    fullImageHeight
  ) {
    this.name = name;
    this.label = label;
    this.attr = attr;
    this.type = type;
    this.helpUrl = helpUrl;
    this.fullImageUrlPath = fullImageUrlPath;
    this.fullImageHeight = fullImageHeight;
    return this;
  }
  constructor4(
    name,
    label,
    attr,
    type,
    helpUrl,
    fullImageUrlPath,
    fullImageHeight,
    smallImageUrlPath,
    smallImageHeight
  ) {
    this.name = name;
    this.label = label;
    this.attr = attr;
    this.type = type;
    this.helpUrl = helpUrl;
    this.fullImageUrlPath = fullImageUrlPath;
    this.fullImageHeight = fullImageHeight;
    this.smallImageUrlPath = smallImageUrlPath;
    this.smallImageHeight = smallImageHeight;
    return this;
  }
}

export const integrations_types = {
  CRM: new IntegrationType('crm', 'CRM'),
  ARCHIVE: new IntegrationType('archive', 'Archive'),
  CONTENT: new IntegrationType('content', 'Content'),
  DATA: new IntegrationType('data', 'Misc'),
};

export const providers = {
  ALLCLIENTSCRM: new IntegrationProvider().constructor4(
    'allclientscrm',
    'All Clients',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360015710031-How-do-I-integrate-with-AllClients-CRM-',
    End_Points.MEDIA_URL + 'dart/images/allclientscrm_logo.png',
    6,
    End_Points.MEDIA_URL + 'dart/images/AllClients_white_square.png',
    6
  ),
  BASECRM: new IntegrationProvider().constructor4(
    'basecrm',
    'Base',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360013238312-How-do-I-integrate-with-Base-CRM-',
    End_Points.MEDIA_URL + 'dart/images/basecrm_logo.png',
    7,
    End_Points.MEDIA_URL + 'dart/images/basecrm_logo.png',
    7
  ),
  CAPSULE: new IntegrationProvider().constructor1(
    'capsule',
    'Capsule',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/115003343452-How-do-I-integrate-with-Capsule-',
    End_Points.MEDIA_URL + 'dart/images/capsule.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/capsulecrm_square.png',
    5
  ),
  COPPER: new IntegrationProvider().constructor4(
    'copper',
    'Copper',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360015151612-How-do-I-integrate-with-Copper-CRM-',
    End_Points.MEDIA_URL + 'dart/images/copper_logo.png',
    4,
    End_Points.MEDIA_URL + 'dart/images/copper_crm_square.png',
    4
  ),
  CLIENTWORKS: new IntegrationProvider().constructor4(
    'clientworks',
    'ClientWorks',
    '',
    integrations_types.CRM,
    '',
    End_Points.MEDIA_URL + 'dart/images/clientworks-square.png',
    8,
    End_Points.MEDIA_URL + 'dart/images/clientworks-square.png',
    8
  ),
  DYNAMICS: new IntegrationProvider().constructor1(
    'dynamics',
    'Dynamics',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360029176311-How-do-I-integrate-with-Microsoft-Dynamics',
    End_Points.MEDIA_URL + 'dart/images/microsoftdynamicscrmlogo.png',
    10,
    End_Points.MEDIA_URL + 'dart/images/microsoft-dynamics-logo-02_square.png',
    10
  ),
  HUBSPOT: new IntegrationProvider().constructor1(
    'hubspot',
    'Hubspot',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/115003170471-How-do-I-integrate-with-Hubspot-',
    End_Points.MEDIA_URL + 'dart/images/Integrations-Hubspot.png',
    6,
    End_Points.MEDIA_URL + 'dart/images/hubspot-sprocket-logo_square.png',
    6
  ),
  MYREPCHAT: new IntegrationProvider().constructor1(
    'myrepchat',
    'MyRepChat',
    integrations_types.CRM,
    '',
    End_Points.MEDIA_URL + 'dart/images/myrepchat_logo.png',
    4,
    End_Points.MEDIA_URL + 'dart/images/myrepchat_square.png',
    4
  ),
  INFUSIONSOFT: new IntegrationProvider().constructor1(
    'infusionsoft',
    'Infusionsoft',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360047122731-How-to-integrate-with-Infusionsoft-CRM',
    End_Points.MEDIA_URL + 'dart/images/infusionsoft.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/infusionsoft.png',
    5
  ),
  JUNXURE: new IntegrationProvider().constructor4(
    'junxure',
    'AdvisorEngine CRM®',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360026224652-How-do-I-integrate-with-Junxure-WEB-UI-',
    End_Points.MEDIA_URL + 'dart/images/junxure_logo.png',
    4,
    End_Points.MEDIA_URL + 'dart/images/junxure_logo.png',
    4
  ),
  LESSANNOYING: new IntegrationProvider().constructor4(
    'lessannoying',
    'Less Annoying',
    'username',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/knowledge/articles/360017511892',
    End_Points.MEDIA_URL + 'dart/images/less_annoying.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/lacrm_logo_square_white_square.png',
    5
  ),
  OFFICE: new IntegrationProvider().constructor4(
    'office',
    'Office 365',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/115003318731-How-do-get-my-contacts-from-Office-365-',
    End_Points.MEDIA_URL + 'dart/images/Office_365_logo.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/office365-logo_square.png',
    5
  ),
  OMNI: new IntegrationProvider().constructor4(
    'omni',
    'Omni',
    'username',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360014285812-How-do-I-integrate-with-Omniscient-CRM-',
    End_Points.MEDIA_URL + 'dart/images/omni_logo.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/Omnilogo_08_square.png',
    5
  ),
  ONEHQ: new IntegrationProvider().constructor1(
    'onehq',
    'One HQ',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360020332072-How-do-I-integrate-with-OneHQ-',
    End_Points.MEDIA_URL + 'dart/images/onehq_logo.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/onehq_square.png',
    5
  ),
  PRACTIFI: new IntegrationProvider().constructor1(
    'practifi',
    'Practifi',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360050972092-How-do-I-integrate-with-Practifi-WEB-UI-',
    End_Points.MEDIA_URL + 'dart/images/PractifiLogo.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/PractifiLogo.png',
    5
  ),
  REDTAIL: new IntegrationProvider().constructor4(
    'redtail',
    'Redtail',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/115003328812-How-do-I-integrate-with-REDTAIL-',
    End_Points.MEDIA_URL + 'dart/images/redtail.jpeg',
    5,
    End_Points.MEDIA_URL + 'dart/images/Redtail_square.png',
    5
  ),
  SALENTICA: new IntegrationProvider().constructor1(
    'salentica',
    'Salentica',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360046584091-How-do-I-integrate-with-Salentica',
    End_Points.MEDIA_URL + 'dart/images/salentica_crm_logo.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/salentica_crm_logo.png',
    5
  ),
  SALESFORCE: new IntegrationProvider().constructor1(
    'salesforce',
    'Salesforce',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/115003327912-How-do-I-integrate-with-Salesforce-',
    End_Points.MEDIA_URL + 'dart/images/Logo-Salesforce-1.png',
    9,
    End_Points.MEDIA_URL + 'dart/images/Salesforce_square.png',
    9
  ),
  SMARTOFFICE: new IntegrationProvider().constructor4(
    'smartoffice',
    'SmartOffice',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/115003332131-How-do-I-integrate-with-SmartOffice-',
    End_Points.MEDIA_URL + 'dart/images/ebix.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/smartoffice_square.png',
    5
  ),
  VTIGER: new IntegrationProvider().constructor4(
    'vtiger',
    'VTiger',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360013236492-How-do-I-integrate-with-VTiger-',
    End_Points.MEDIA_URL + 'dart/images/vtiger_logo.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/vtiger_square.png',
    5
  ),
  WEALTHBOX: new IntegrationProvider().constructor4(
    'wealthbox',
    'Wealthbox',
    'token',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/115003329392-How-do-I-integrate-with-Wealthbox-',
    End_Points.MEDIA_URL + 'dart/images/wealthbox.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/Wealthbox_square.jpg',
    5
  ),
  ZOHO: new IntegrationProvider().constructor1(
    'zoho',
    'Zoho',
    integrations_types.CRM,
    'https://ionlake.zendesk.com/hc/en-us/articles/360041874952-How-do-I-integrate-with-Zoho-CRM-',
    End_Points.MEDIA_URL + 'dart/images/zoho_logo.png',
    5,
    End_Points.MEDIA_URL + 'dart/images/zoho_logo.png',
    5
  ),

  EMAIL: new IntegrationProvider().constructor2(
    'email',
    'Email',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008487311-How-do-I-archive-to-my-email-',
    '',
    0
  ),
  ERADO: new IntegrationProvider().constructor2(
    'erado',
    'Erado',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008312292-How-do-I-archive-with-Erado-',
    End_Points.MEDIA_URL + 'dart/images/erado-gray-logo.png',
    0
  ),
  GLOBALRELAY: new IntegrationProvider().constructor2(
    'globalrelay',
    'Global Relay',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360018488411-How-do-I-set-up-archiving-with-Global-Relay-',
    End_Points.MEDIA_URL + 'dart/images/gr-logo.png',
    0
  ),
  MESSAGEWATCHER: new IntegrationProvider().constructor2(
    'messagewatcher',
    'MessageWatcher',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008485791-How-do-I-archive-to-Message-Watcher-',
    End_Points.MEDIA_URL + 'dart/images/messagewatcher-logo-2.png',
    0
  ),
  MICROFOCUS: new IntegrationProvider().constructor2(
    'microfocus',
    'MicroFocus',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360000489091-Can-I-have-MyRepChat-archive-my-messages-for-me-',
    End_Points.MEDIA_URL + 'dart/images/Micro_Focus_logo.png',
    0
  ), //FIXME/
  MSJOURNAL: new IntegrationProvider().constructor2(
    'msjournal',
    'Msjournal',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360000489091-Can-I-have-MyRepChat-archive-my-messages-for-me-',
    End_Points.MEDIA_URL + 'dart/images/msjournal_logo.png',
    0
  ),
  OFFICEARCHIVE: new IntegrationProvider().constructor2(
    'officearchive',
    'OfficeArchive',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360000489091-Can-I-have-MyRepChat-archive-my-messages-for-me-',
    End_Points.MEDIA_URL + 'dart/images/Office_365_logo.png',
    0
  ),
  ONESECURE: new IntegrationProvider().constructor2(
    'onesecure',
    'OneSecure',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360039741771-How-do-I-archive-to-OneSecure-Web-UI-',
    End_Points.MEDIA_URL + 'dart/images/onesecure_logo.png',
    0
  ),
  PATRINA: new IntegrationProvider().constructor2(
    'patrina',
    'Patrina',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008312992-How-do-I-archive-with-Patrina-',
    End_Points.MEDIA_URL + 'dart/images/patrina_logo.png',
    0
  ),
  PROOFPOINT: new IntegrationProvider().constructor2(
    'proofpoint',
    'ProofPoint',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008316352-How-do-I-archive-to-Proofpoint-',
    End_Points.MEDIA_URL + 'dart/images/proofpoint_logo.png',
    0
  ),
  SLACK: new IntegrationProvider().constructor2(
    'slack',
    'Slack',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008483731-How-do-I-archive-to-Slack-',
    End_Points.MEDIA_URL + 'dart/images/Slack.png',
    0
  ),
  SMARSH: new IntegrationProvider().constructor2(
    'smarsh',
    'Smarsh',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008316752-How-do-I-archive-to-Smarsh-',
    End_Points.MEDIA_URL + 'dart/images/smarsh-logo.png',
    0
  ),
  VAULTAMERICA: new IntegrationProvider().constructor2(
    'vaultamerica',
    'Vaultamerica',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008316752-How-do-I-archive-to-vaultamerica-',
    End_Points.MEDIA_URL + 'dart/images/vaultamerica.png',
    0
  ),
  INTRADYN: new IntegrationProvider().constructor2(
    'intradyn',
    'Intradyn',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008487311-How-do-I-archive-to-intradyn-',
    End_Points.MEDIA_URL + 'dart/images/intradyn.png',
    0
  ),
  SFTP: new IntegrationProvider().constructor2(
    'filetransfer',
    'filetransfer',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008487311-How-do-I-archive-to-intradyn-',
    End_Points.MEDIA_URL + 'dart/images/sftp_logo.png',
    0
  ),
  MIMECAST: new IntegrationProvider().constructor2(
    'mimecast',
    'Mimecast',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008487311-How-do-I-archive-to-mimecast-',
    End_Points.MEDIA_URL + 'dart/images/mimecast.png',
    0
  ),
  MIRRORWEB: new IntegrationProvider().constructor2(
    'mirrorweb',
    'Mirrorweb',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008487311-How-do-I-archive-to-mirrorweb-',
    End_Points.MEDIA_URL + 'dart/images/mirrorweb.jpg',
    0
  ),
  RIAINABOX: new IntegrationProvider().constructor2(
    'riainabox',
    'Ria in a Box',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008487311-How-do-I-archive-to-riainabox-',
    End_Points.MEDIA_URL + 'dart/images/riainabox.jpg'
  ),
  RIACOMPLIANCETECHNOLOGY: new IntegrationProvider().constructor2(
    'riacompliancetechnology',
    'RIA Compliance Technology',
    integrations_types.ARCHIVE,
    'https://ionlake.zendesk.com/hc/en-us/articles/360008487311-How-do-I-archive-to-riacompliancetechnology-',
    End_Points.MEDIA_URL + 'dart/images/riacompliancetechnology.jpg'
  ),
  FMGSUITE: new IntegrationProvider().constructor3(
    'fmgsuite',
    'FMG Suite',
    'customerId',
    integrations_types.CONTENT,
    'https://ionlake.zendesk.com/hc/en-us/articles/360034435972-How-do-I-integrate-with-FMG-Suite-',
    End_Points.MEDIA_URL + 'dart/images/fmg-logo.png',
    5
  ),
  MARKETINGPRO: new IntegrationProvider().constructor3(
    'marketingpro',
    'MarketingPro',
    'usercode',
    integrations_types.CONTENT,
    'https://ionlake.zendesk.com/hc/en-us/articles/360018567231-How-do-I-integrate-with-MarketingPro-',
    End_Points.MEDIA_URL + 'dart/images/marketingpro_logo.png',
    5
  ),

  RISKALYZE: new IntegrationProvider().constructor2(
    'riskalyze',
    'Riskalyze',
    integrations_types.DATA,
    'https://ionlake.zendesk.com/hc/en-us/articles/360016261131-How-do-I-integrate-with-Riskalyze-',
    End_Points.MEDIA_URL + 'dart/images/riskalyze_logo.png',
    5
  ),
};

export class Integration {
  constructor1(id, type, provider, active, enabled, locked, realtimeNotes) {
    this.id = id;
    this.type = type;
    this.provider = provider;
    this.active = active;
    this.enabled = enabled;
    this.locked = locked;
    this.realtimeNotes = realtimeNotes;
    this.attrs = {};
    return this;
  }
  constructor2(type, provider, active, enabled, locked, realtimeNotes) {
    return this.constructor1(
      null,
      type,
      provider,
      active,
      enabled,
      locked,
      realtimeNotes
    );
  }

  constructor3(id, provider) {
    let x = this.constructor1(
      id,
      provider.getType(),
      provider.getProvider(),
      provider.isActive(),
      provider.isEnabled(),
      provider.isLocked(),
      provider.isRealtimeNotes()
    );
    x.attrs = getAttributes();
    return x;
  }
}

// public static class IntegrationAttribute {
//     private Integer providerId;
//     private String key;
//     private String value;

//     public IntegrationAttribute(Integer providerId, String key, String value) {
//         this.providerId = providerId;
//         this.key = key;
//         this.value = value;
//     }

//     public IntegrationAttribute(String key, String value) {
//         this.key = key;
//         this.value = value;
//     }

//     public Integer getProviderId() {
//         return providerId;
//     }

//     public String getKey() {
//         return key;
//     }

//     public String getAttribute() {
//         return value;
//     }

//     public Long getAttributeAsLong() {
//         if (value == null)
//             return null;
//         return Long.parseLong(value);
//     }

//     public Date getAttributeAsDate() {
//         if (value == null)
//             return null;
//         return new Date(Long.parseLong(value));
//     }

//     public Boolean getAttributeAsBoolean() {
//         if (value == null)
//             return null;
//         return Boolean.parseBoolean(value);
//     }

//     public String toString() {
//         return "Attr[" + value + "]";
//     }
// }	public static class IntegrationAttribute {
//     private Integer providerId;
//     private String key;
//     private String value;

//     public IntegrationAttribute(Integer providerId, String key, String value) {
//         this.providerId = providerId;
//         this.key = key;
//         this.value = value;
//     }

//     public IntegrationAttribute(String key, String value) {
//         this.key = key;
//         this.value = value;
//     }

//     public Integer getProviderId() {
//         return providerId;
//     }

//     public String getKey() {
//         return key;
//     }

//     public String getAttribute() {
//         return value;
//     }

//     public Long getAttributeAsLong() {
//         if (value == null)
//             return null;
//         return Long.parseLong(value);
//     }

//     public Date getAttributeAsDate() {
//         if (value == null)
//             return null;
//         return new Date(Long.parseLong(value));
//     }

//     public Boolean getAttributeAsBoolean() {
//         if (value == null)
//             return null;
//         return Boolean.parseBoolean(value);
//     }

//     public String toString() {
//         return "Attr[" + value + "]";
//     }
// }

export class NoteFrequency {
  constructor(key, desc, isDefault) {
    this.key = key;
    this.desc = desc;
    this.isDefault = isDefault;
  }
}

export const EnumFrequency = {
  NEVER: new NoteFrequency('never', 'Never', false),
  DAILY: new NoteFrequency('daily', 'Daily', true),
  REALTIME: new NoteFrequency('realtime', 'Real Time', false),
};

export class NoteLocation {
  constructor(key, desc, isDefault) {
    this.key = key;
    this.desc = desc;
    this.isDefault = isDefault;
  }
}
export const EnumLocation = {
  TASK: new NoteFrequency('task', 'Activity - Task', false),
  CALL: new NoteFrequency('call', 'Activity - Call', false),
  EMAIL: new NoteFrequency('email', 'Activity - Email', false),
  NOTE: new NoteFrequency('note', 'Notes', true),


};
