import React from 'react';
import { useSelector } from 'react-redux';
import defaultImage from '../../Assets/Images/splashlogo.png';
import End_Points from '../../Constants/Api';
import { providers } from '../IntegrationProvider';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { Icon } from 'react-native-elements';
import moment from 'moment';
import ConsentStatusIndicator from '../../Components/ConsentStatusIndicator';

const MessagePreview = ({ i, onPress, focused, unreadMessages }) => {
    let image = defaultImage;
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == i.source);
    if (provider) {
        image = provider.smallImageUrlPath;
        imageAlt = `${provider.name} Logo.`
    }

    const { tenant } = useSelector((state) => state.User.profile);

    const accountId = i?.accountId || null;

    const oldestPermissibleDate = moment().subtract(tenant?.messageHistory, 'days');

    if (moment(i?.when).isBefore(oldestPermissibleDate)) {
        return null;
    }

    const consentStatus = i?.stopped ? 'stopped' : i?.consented ? 'consented' : null;

    return (
        <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            onPress={() => {
                onPress()
            }}
            style={[
                styles.container,
                {
                    backgroundColor: focused ? '#ECF6FF' : 'transparent',
                    borderStyle: focused ? 'solid' : 'none',
                }
            ]}>
            <View style={{ width: 40, justifyContent: 'center', marginRight: 15 }}>
                { !i?.isGroup ?
                    <img
                    src={image}
                    alt={imageAlt}
                    style={{ height: 40, width: 40, borderRadius: 20 }} />
                    :
                    <Icon name='group' type='material' size={40} color={AppColor.PrimaryColor} />
                }

                {unreadMessages > 0 && (
                    <View
                        style={styles.unreadMessageIndicator}>
                        <Text
                            style={{
                                fontFamily: AppFonts.Roboto_Bold,
                                fontSize: 12,
                                color: 'white',
                            }}>
                            {unreadMessages}
                        </Text>
                    </View>
                )}
            </View>

            <View style={{flex:1}}>
                <View style={{flexDirection:'row',alignItems:'center',gap:5}}>
                <Text

                    style={{
                        color: AppColor.PrimaryColor,
                        fontFamily: AppFonts.Roboto_Regular,
                        fontSize: 16,
                        marginBottom: 2,
                        fontWeight: unreadMessages > 0
                                ? 'bold'
                                : 'normal',
                    }}>
                    {i.fname + ' ' + i.lname}
                </Text>

                {
                    !i?.isGroup &&
                    <ConsentStatusIndicator status={consentStatus} size={18} />
                }

                </View>

                {
                    i.messageType === 'call' &&

                    <View style={{ flexDirection: 'row' }}>
                        {(i.direction === 'inbound' || i.direction === 'Inbound Call') &&
                            <Icon
                                style={{ fontSize: 20, color: 'green' }}
                                name={'call-received'}
                                type={'MaterialIcons'}
                            />
                        }
                        {i.direction == 'outbound' && (
                            <Icon
                                style={{ fontSize: 20, color: 'red' }}
                                name={'call-missed'}
                                type={'MaterialIcons'}
                            />
                        )}
                        <Text
                            style={{
                                color: AppColor.Dark,
                                fontFamily: AppFonts.Roboto_Regular,
                                fontSize: 14,
                            }}>
                            {i.direction}
                        </Text>
                    </View>
                }

                {(i.messageType === 'sms' || (i?.isGroup && i?.message)) && (
                    <Text
                    numberOfLines={2}
                        style={{
                            color: AppColor.Dark,
                            fontFamily: AppFonts.Roboto_Regular,
                            fontWeight: unreadMessages > 0
                                ? 'bold'
                                : 'normal',
                        }}>
                        {i.message}
                    </Text>
                )}

                {(i.messageType === 'mms' || i.source === 'bandwidth' && i.message === '') && (
                    <MaterialIcons
                        name="perm-media"
                        size={22}
                        color={AppColor.PrimaryColor} />
                )}

                <Text style={{color:'rgba(0,0,0,.5)',fontSize:12}}>{i.status}</Text>
            </View>

            {TimestampDetails(accountId, i)}

        </TouchableOpacity>
    );
}

export default MessagePreview;

function TimestampDetails(accountId, i) {
    return <View
        style={{
            flex: 1,
            justifyContent: 'center',
            height: 60,
            alignItems: 'flex-end',
        }}>
        {accountId && <View style={{ flexDirection: 'row' }}>
            <Icon size={12} name='building-o' type='font-awesome' />
            <Text style={{ marginLeft: 2 }}>{accountId}</Text>
        </View>}
        <Text
            style={{
                color: AppColor.Dark,
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 14,
            }}>
            {moment(i.when).format('h:mm a')}
        </Text>
        <Text
            style={{
                color: AppColor.Dark,
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 14,
            }}>
            {moment(i.when).format('MMM DD YYYY')}
        </Text>

    </View>;
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
        padding: 10,
        alignItems: 'flex-start',
        borderColor: '#116AB4',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        flexDirection: 'row',
    },
    unreadMessageIndicator: {
        backgroundColor: 'red',
        height:20,
        width:20,
        borderRadius: 100,
        position: 'absolute',
        right: -10,
        top: -5,
        alignItems: 'center',
        justifyContent: 'center',
    }
})