import React from "react";
import { Content, Wrapper } from "../../Components/Layout";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import NewAlphabetList from "../../Screens/Contacts/NewAlphabetList";
import ListHeader from "../../Components/AlphabetList/ListHeader";
import AppColor from "../../Constants/Color";
import AppFonts from "../../Constants/Fonts";
import { Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import Search from "../../Components/Search";

const Logins = ({route, navigation}) => {
    const {data, isLoading} = getQuery({action: 'get_users', tenantId: 0});

    if(isLoading) return <Loader backgroundColor={'transparent'}/>
    if(!data) return null;


    const renderContactList = ({ item }) => {

        const ACCOUNT_STATUS = {
            1: "Active",
            2: "Inactive",
            3: "Review",
            4: "Trial",
            5: "Default",
            90: "Comp",
        }

        return (
            <TouchableOpacity onPress={() => navigation.navigate('Login', {user: item})} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ width: '20%', flexDirection: 'row', alignItems: 'center' }}>
                    <Icon name='user' type='font-awesome' size={28} style={{ marginLeft: 12, marginRight: 12 }} />
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{item.name}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular,maxWidth:'80%' }}>{item.email}</Text>
                </View>
                <View style={{ width: '20%',flexDirection:'row',alignItems:'center' }}>
                    <Icon name={item.mfaType === 'mobile' ? 'mobile-phone' : 'envelope-o'} type='font-awesome' size={item.mfaType === 'mobile' ? 26 : 20} />
                    <Text style={{ marginLeft:5,textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{item.mfaType}</Text>
                </View>

                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'center', fontFamily: AppFonts.Roboto_Regular, backgroundColor: AppColor.PrimaryColor, paddingVertical: 2, paddingHorizontal: 5, borderRadius: 5, width: 100, color: 'white' }}>{ACCOUNT_STATUS[item.status] || 'Unknown'}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{item.permissions[0]?.label || ''}</Text>
                </View>


            </TouchableOpacity>
        )
    }

    return(
        <Wrapper>
            <Content>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Search actionButton actionButtonTitle={'Login'} onPressActionButton={() => navigation.navigate('Login', {
                user : {
                    "email": "",
                    "name": "",
                    "mobile": "",
                    "mobilecc": "US",
                    "status": 0,
                    "affiliate": 0,
                    "pushKey": null,
                    "mfaType": "mobile",
                    "permissions": [
                    ],
                    "accounts": [
                    ],
                }
            })} containerStyle={{ flex: 1 }} />
            </View>
                <NewAlphabetList
                    data={data.users}
                    sortBy={'name'}
                    RenderItem={renderContactList}
                    ListHeaderComponent={() => 
                    <ListHeader 
                    sortBy={null} 
                    columns={['Name', 'Email', 'MFA Type', 'Status', 'Permission']}
                    />
                }
                />

            </Content>
        </Wrapper>
    )
}

export default Logins;