import React from 'react'
import {View, TouchableOpacity} from 'react-native';
import { InputToolbar } from 'react-native-gifted-chat';
import { CheckBox } from 'react-native-elements';
import Entypo from 'react-native-vector-icons/Entypo';
import { Icon } from 'react-native-elements';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import RequestMaker from '../../Middleware/ApiCaller';
import { useToast } from 'native-base';

const MessageInput = props => {
    const consent = props.consent;
    const Toast = useToast();

    const sendDistributionListConsent = async () => {
      try{
        const body = {
          action:"send_consent",
          distributionList: props.dl.id
        }

        const result = await RequestMaker(body);
        if(result.data.success && (!result.data.failures || result.data.failures.length === 0)){
          Toast.show({
            duration: 2000,
            title: 'Consent sent successfully',
            status: 'success',
          });
        } else if(result.data.failures && result.data.failures.length > 0){
          result.data.failures.forEach(failure => {
            Toast.show({
              duration: 2000,
              title: failure,
              status: 'error',
            });
          })
        } else {
          throw new Error('Failed to send consent');
        }

      } catch(err){
        Toast.show({
          duration: 2000,
          title: 'Failed to send consent',
          status: 'error',
        });
      }
    }

    return(
    <InputToolbar
        {...props.toolBarProps}
        
        // renderAccessory={() => (
        //   // <CheckBox
        //   //   checked={props.checked}
        //   //   onPress={props.onCheck}
        //   //   title="Enter To Send"
        //   //   containerStyle={{
        //   //     backgroundColor: 'transparent',
        //   //     borderWidth: 0,
        //   //   }}
        //   //   titleProps={{
        //   //     style: {
        //   //       fontFamily: AppFonts.Roboto_Regular,
        //   //       color: AppColor.Dark,
        //   //     },
        //   //   }}
        //   // />
        // )}
        primaryStyle={{
          paddingHorizontal: 5,
        }}
        accessoryStyle={{
        }}
        renderSend={() => (
          <View style={{padding:5,alignItems:'flex-start'}}>
            <CheckBox
            checked={props.checked}
            onPress={props.onCheck}
            title="Enter To Send"
            style={{marginLeft:0}}
            containerStyle={{
              backgroundColor: 'transparent',
              borderWidth: 0,
              marginLeft:0,
              paddingLeft:0
            }}
            titleProps={{
              style: {
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.Dark,
              },
            }}
          />

          <View
            style={{
              height:'100%',
              flexDirection: 'row',
              alignItems: 'center',
              gap:30
            }}>
              {
                props.dl &&
                <TouchableOpacity
                accessibilityRole='button'
                accessibilityLabel='Smile'
                accessibilityHint='Select emoji.'
                disabled={!consent}
                onPress={sendDistributionListConsent}>
                <Icon
                  name="thumbs-up"
                  type='feather'
                  size={25}
                  color={'black'}
                />
              </TouchableOpacity>
  
  
              }
            <TouchableOpacity
              accessibilityRole='button'
              accessibilityLabel='Smile'
              accessibilityHint='Select emoji.'
              disabled={!consent}
              onPress={props.onPressEmoji}>
              <Icon
                name="smile"
                type='feather'
                size={25}
                color={'black'}
              />
            </TouchableOpacity>

            <TouchableOpacity
              accessibilityRole='button'
              accessibilityLabel='Attachment'
              accessibilityHint='Attach a document.'
              disabled={!consent}
              onPress={props.onPressAttachment}>
              <Entypo
                name="attachment"
                size={25}
                color={'black'}
              />
            </TouchableOpacity>
            <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            accessibilityLabel='Send'
              onPress={props.onPressSend}
              activeOpacity={1}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Icon color={'black'} type={'font-awesome'} name={'send'} />
            </TouchableOpacity>
          </View>
          </View>
        )}
        containerStyle={{
          borderTopColor: AppColor.GREY100,
        }}
      />
      )
}

export default MessageInput;