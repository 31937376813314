import React from "react";
import { Modal, ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import ComponentAccordion from "../../Components/ComponentAccordion";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import { useSelector } from "react-redux";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";
import { RemovePhoneModal } from "../../Screens/Contacts/modals";
import ModalWrapper from "../../Components/Modals/ModalWrapper";

const ResellerLogins = ({ reseller }) => {
    const Toast = useToast();
    const [focusedLogin, setFocusedLogin] = React.useState(null);
    const [removeModalVisible, setRemoveModalVisible] = React.useState(false);
    const [passwordModal, setPasswordModal] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordConfirm, setPasswordConfirm] = React.useState('');

    const { data, isLoading, refetch } = getQuery({
        action: 'get_logins',
        resellerId: reseller.id,
        type: 'reseller'
    })

    if (isLoading) return <Loader backgroundColor="transparent" />
    if (!data) return null;

    const ACCOUNT_STATUS = {
        0: 'Inactive',
        1: 'Active',
        2: 'Temporary',
        3: 'Review'
    }

    const RenderComponent = ({ item }) => {
        const [data, setData] = React.useState({
            name: item?.name || '',
            email: item?.email || '',
        })

        const handleSave = async () => {
            try {
                const body = {
                    type: "reseller",
                    resellerId: reseller.id,
                    ...data
                }
                if (item?.user_id) {
                    body.action = 'update_login';
                    body.id = item.user_id;
                } else {
                    body.action = 'create_login';
                }
                const result = await RequestMaker(body);
                if (result.data.success) {
                    refetch();
                    Toast.show({
                        title: "Login Saved",
                        status: "success",
                    })
                } else if (result.data.errorMessage) {
                    Toast.show({
                        title: result.data.errorMessage,
                        status: "error",
                    })
                }
            } catch (e) {
                Toast.show({
                    title: e.message,
                    status: "error",
                })
            }

        }

        const handleResetMFA = async () => {
            const body = {
                action: 'reset_mfa',
                userId: item.user_id
            }
            try {
                const result = await RequestMaker(body);
                if (result.data.success) {
                    refetch();
                    Toast.show({
                        title: "MFA Reset Successfully",
                        status: "success",
                    })
                } else if (result.data.errorMessage) {
                    Toast.show({
                        title: result.data.errorMessage,
                        status: "error",
                    })
                }
            } catch (e) {
                Toast.show({
                    title: e.message,
                    status: "error",
                })
            }
        }

        const handleRemove = () => {
            setFocusedLogin(item);
            setRemoveModalVisible(true);
        }

        const handleChangePassword = () => {
            setPasswordModal(true);
            setFocusedLogin(item);
        }

        const handleApprove = async () => {
            const body = {
                action: 'update_login',
                id: item.user_id,
                type: 'reseller',
                resellerId: reseller.id,
                name: item.name,
                email: item.email,
                status: 1,
			};
            try{
            const result = await RequestMaker(body);
            if(result.data.success){
                refetch();
                Toast.show({
                    title: "Login Approved",
                    status: "success",
                })
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: "error",
                })
            }
            } catch (e) {
                Toast.show({
                    title: e.message,
                    status: "error",
                })
            }

        }



        return (
            <>
                <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>
                    Name
                </Text>
                <CustomInput onChangeText={text => setData({ ...data, name: text })} value={data.name} />
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>
                    E-mail
                </Text>
                <CustomInput
                    disabled={item?.email}
                    onChangeText={text => setData({ ...data, email: text })}
                    value={data.email} />
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: 10, gap: 5 }}>
                    {
                        item.status === 3 &&
                        <CustomButton width={'20%'} onPress={handleApprove} outline title={'Approve'} />
                    }
                    {
                        (item?.user_id && item.status !== 3 )&&
                        <>
                            <CustomButton onPress={handleResetMFA} title={'Reset MFA'} outline width={'20%'} />
                            <CustomButton onPress={handleChangePassword} title={'Change Password'} outline width={'30%'} />
                        </>
                    }
                    {

                        item?.user_id &&
                        <>
                            <CustomButton onPress={handleRemove} title={'Remove'} outline width={'20%'} />

                        </>
                    }
                    <CustomButton onPress={handleSave} width={'20%'} title="Save" />
                </View>
            </>
        )
    }

    const handleDelete = async (id) => {
        const body = {
            action: 'delete_login',
            id: focusedLogin.user_id,
            type: 'reseller',
            resellerId: reseller.id,
        }
        try {
            const result = await RequestMaker(body);
            if (result.data.success) {
                refetch();
                Toast.show({
                    title: "Login Deleted",
                    status: "success",
                })
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: "error",
                })
            }
        } catch (e) {
            Toast.show({
                title: e.message,
                status: "error",
            })
        }
    }

    const handleChangePassword = async () => {
        if (password !== passwordConfirm) {
            Toast.show({
                title: "Passwords must match",
                status: "error",
            })
            return;
        }
        const body = {
            action: 'reset_password',
            userId: focusedLogin.user_id,
            password: password,
            sendEmail: false,
            username: focusedLogin.email
        }
        try {
            const result = await RequestMaker(body);
            if (result.data.success) {
                refetch();
                setPasswordModal(false);
                Toast.show({
                    title: "Password Changed",
                    status: "success",
                })
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: "error",
                })
            }
        } catch (e) {
            Toast.show({
                title: e.message,
                status: "error",
            })
        }

    }


    return (
        <ScrollView style={{ flex: 1,padding:10 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginBottom: 20 }}>
                Manage logins for this account. Use the Add button to open the settings panel and provide a name and an email address and click on save to create an account login. If this email address is not currently being used, a generated password will be emailed to the email address, the user can change their password after they login.
                {'\n'}{'\n'}
                To remove an existing account login just select the login row to display the setting and click the remove button.
                {'\n'}{'\n'}
                Use the Reset MFA button to clear out a users two-factor authentication. They will be prompted to re-enter in upon next login.
            </Text>

            <ComponentAccordion
                allowAdd={true}
                columns={['Name', 'E-mail', 'Status']}
                data={data.logins}
                renderListItem={item => [item.name, item.email, ACCOUNT_STATUS[item.status]]}
                renderComponent={item => <RenderComponent item={item} />}
            />

            { removeModalVisible && 
            <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={removeModalVisible} transparent={true}>
                <RemovePhoneModal
                    title="Delete Login"
                    message="Are you sure you want to delete this login?"
                    handleRemove={() => {
                        handleDelete(focusedLogin.id);
                        setRemoveModalVisible(false);
                    }}
                    setRemoveModal={setRemoveModalVisible}
                />
            </Modal>
            }

            {passwordModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={passwordModal} transparent={true}>
                    <ModalWrapper>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Change Password</Text>
                        <CustomInput secure onChangeText={val => setPassword(val)} titleStyle={{ marginTop: 10 }} title={'New Password'} />
                        <CustomInput secure onChangeText={val => setPasswordConfirm(val)} titleStyle={{ marginTop: 10 }} title={'Confirm Password'} />
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginTop: 20 }}>
                            <CustomButton title={'Cancel'} outline onPress={() => setPasswordModal(false)} width={'40%'} />
                            <CustomButton title={'Save'} onPress={handleChangePassword} width={'40%'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }



        </ScrollView>
    )
}

export default ResellerLogins;