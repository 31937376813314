import React from "react";
import { Text } from "react-native";
import AppFonts from "../../../Constants/Fonts";
import SimpleCheckbox from "../../../Components/SimpleCheckbox";

const Permissions = ({user, setUser}) => {
    const handleUpdatePermissions = (id) => {

        if (user.permissions.includes(id)) {
            // Remove the permission with the given id
            setUser({
                ...user,
                permissions: [...user.permissions.filter(e => e !== id)]
            });
        } else {
            // Add the new permission
            setUser({
                ...user,
                permissions: [...user.permissions,  id]
            });
        }
    }
    

    return(
        <>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:20}}>
            Permissions allow you to control what users of this account can and cannot do.
            </Text>
            <SimpleCheckbox label={'MyRepChat User'} 
            onChange={() => handleUpdatePermissions(13)}
            value={user.permissions.some(e => e === 13)}
            />
            <SimpleCheckbox label={'Reseller'} 
            onChange={() => handleUpdatePermissions(16)}
            value={user.permissions.some(e => e === 16)}
            />
            <SimpleCheckbox label={'Group Administrator'} 
            onChange={() => handleUpdatePermissions(15)}
            value={user.permissions.some(e => e === 15)}
            />
            <SimpleCheckbox label={'System Administrator'} 
            onChange={() => handleUpdatePermissions(1)}
            value={user.permissions.some(e => e === 1)}
            />


        </>
    )
}

export default Permissions;