import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import { Icon } from 'react-native-elements';
import AppFonts from '../../Constants/Fonts';
import End_Points from '../../Constants/Api';
import { providers } from '../IntegrationProvider';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../Middleware/helpers';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

const MessageHeader = props => {
    const [hover, setHover] = React.useState(false);
    const member = props.member;
    const dl = props.dl;
    const tenant = useSelector((state) => state.User?.profile?.tenant);
    const {isWebCompact} = useResponsiveLayout();

    let countryCode;
    let number;
    let formattedNumber;

    if(props.member){
      countryCode = member.phoneNumbers?.find((x) => x.messaging)?.countryCode
      number = member.phoneNumbers?.find((x) => x.messaging)?.longCode
      formattedNumber = formatNumber(`${countryCode}${number}`);
  
    }

    return(
        <View
            style={{
              width: '100%',
              backgroundColor: 'white',
              paddingVertical: 5,
              paddingHorizontal: 10,
              paddingBottom:15
            }}>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}>
                { isWebCompact && <Icon name='arrow-back' onPress={() => {
                  if(props.setSelectedChat){
                    props.setSelectedChat(null);
                  }
                }} />}
              <View>
                {member && <HeaderLogo member={member} />}
              </View>
              <TouchableOpacity
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onPress={ member?.isGroup ? null : props.onPress}
                style={{
                  flex: 1,
                  justifyContent: 'flex-start',
                  alignItems:'flex-start',
                  paddingHorizontal: 15,
                }}>
                {dl == null ? (
                  <>
                    <Text
                      style={{
                        fontFamily: AppFonts.Roboto_Regular,
                        fontSize: 20,
                        color: 'black',
                        textDecorationLine: hover ? 'underline' : 'none',
                      }}
                      numberOfLines={1}>
                      {member.fname} {member.mname} {member.lname}
                    </Text>
                    { !member?.isGroup && <Text
                      style={{
                        fontFamily: AppFonts.Roboto_Regular,
                        fontSize: 14,
                        color: 'black',
                        textDecorationLine: hover ? 'underline' : 'none',
                      }}>
                        {formattedNumber}
                    </Text>}
                  </>
                ) : (
                  <View style={{flexDirection:'row',alignItems:'center',gap:20}}>
                  <MaterialCommunityIcons name='account-group' size={32} />
                  <Text
                    style={{
                      textDecorationLine: hover ? 'underline' : 'none',
                      fontFamily: AppFonts.Roboto_Regular,
                      fontSize: 20,
                      color: 'black',
                    }}>
                    {dl.text}
                  </Text>
                  </View>
                )}
              </TouchableOpacity>

              {(member && tenant?.voice && !member?.isGroup) &&
              <TouchableOpacity
                onPress={props.onPhonePress}>
                <Icon color={'black'} type={'feather'} name={'phone'} />
              </TouchableOpacity>
              }

            </View>
          </View>
    )
}


const HeaderLogo = ({member}) => {
    
    let image = require('../../Assets/Images/splashlogo.png');
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == member.source);
    if(member.source){
      if (provider) {
        image = provider.smallImageUrlPath;
        imageAlt = `${provider.name} logo.`
      }
    }

    if(member?.isGroup){
      return(
        <Icon name='group' type='material' size={40} style={{marginLeft:20}} />
      )
    }


    return (
      <img
        alt={imageAlt}
        style={{ width: 40, height: 40, borderRadius: 35, marginLeft: 20 }}
        src={image}
      />
    );
  };



export default MessageHeader;