/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Image,
  Keyboard,
} from 'react-native';
import { useSelector } from 'react-redux';
import Alert from 'Alert';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container, useToast } from 'native-base';
import storeData from '../../../AsyncUtils/StoreData';
import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import CustomButton from '../../../Components/Button';
import AppFonts from '../../../Constants/Fonts';
import Loader from '../../../Components/Loader';
import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';
import keyword from '../../../Constants/Keywords';

const VerifyEmail = (props) => {
  const [otp, setOtp] = useState('');
  const [loader, setLoading] = useState(false);
  const signupdata = useSelector((state) => state.signupdata);
  const Toast = useToast();
  const verifyOtp = async () => {
    Keyboard.dismiss();
    let body = {
      action: 'verify_email',
      code: otp,
      email: signupdata.email,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    setLoading(true);
    let result = await RequestMaker(Config);
    if (result.data.success) {
      signup();
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
    setLoading(false);
  };


  const signup = async () => {
    Keyboard.dismiss();
    console.log(signupdata);
    let body = {
      action: 'signup',
      ...signupdata,
      timezone: '',
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    setLoading(true);
    let result = await RequestMaker(Config);
    if (result.data.success) {
      Toast.show({
        duration: 2000,
        title: 'Successfully registered',
        status: 'success',
      });
      storeData(
        keyword.user_auth,
        JSON.stringify({
          username: signupdata.email,
          password: signupdata.password,
        })
      ).catch((err) => {});
      props.navigation.navigate('Login');
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
    setLoading(false);
  };


  return (
    <SafeAreaView style={styles.container}>
      {loader ? <Loader /> : null}
      <Container
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 767,
          paddingHorizontal: 15,
        }}>
        <View
          style={{
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              width: '100%',
            }}>
            <img
              style={{
                maxWidth: 300,
                alignSelf: 'center',
                resizeMode: 'contain',
              }}
              alt='MyRepChat Logo'
              src={require('../../../Assets/Images/logo-fullcolor@2x.png')}
            />
          </View>
          <View style={{ width: '100%' }}>
            <View
              style={{
                alignSelf: 'center',
                paddingVertical: 10,
              }}>
              <Text
                style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 16 }}>
                We sent an email with a verification code to {signupdata.email}.
                Please find the email and enter the verification code from the
                email into the space provided below. Don't forget to check your
                junk mail.
              </Text>
            </View>
            <CustomInput
              onChangeText={(s) => setOtp(s)}
              value={otp}
              title={'Verification Code*'}
              placeholder={'Enter Verification code'}
            />
          </View>
          <View style={{ height: 15 }} />
          <CustomButton
            onPress={() => {
              verifyOtp();
            }}
            title={'Next'}
          />
          <View style={{ height: 15 }} />
          <CustomButton
            onPress={() => props.navigation.goBack()}
            title={'Back'}
          />
        </View>
      </Container>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: AppColor.SecondaryColor,
  },
});

export default VerifyEmail;
