
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import AppColor from "../../Constants/Color";
import AppFonts from "../../Constants/Fonts";
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

const ListHeader = ({sortBy, handleSortByProperty, columns, style}) => {
    const columnsArray =  Array.isArray(columns) ? columns : [];

    const width = 100 / columnsArray.length;
    const widthPercentage = `${width}%`;
    

    const handlePressColumnHeaderText = (e) => {
        if(handleSortByProperty){
            handleSortByProperty(e);
        }
    }

    const getHeaderText = (e) => {
        switch(e){
            case 'fname':
                return 'First Name';
            case 'lname':
                return 'Last Name';
            case 'email_address':
                return 'Email';
            case 'status':
                return 'Status';
            case 'phone':
                return 'Phone Number';
            case 'actions':
                return 'Actions';
            default:
                return e;
        }
    }

    return (
        <View style={styles.container}>
            {
                columnsArray.map((e,i) => {
                    return(
                    <TouchableOpacity style={{width: widthPercentage, paddingLeft: i === 0 ? 50 : 0}} onPress={() => handlePressColumnHeaderText(e)}>
                        <Text
                            numberOfLines={1}
                            style={[styles.columnText, { paddingLeft: 0 }]}>
                            { getHeaderText(e) }
                            {sortBy === e ?
                                <FontAwesome5 style={styles.sortIcon} size={17} name='sort-alpha-down' />
                                :
                                sortBy === `${e}-desc` ?
                                <FontAwesome5 style={styles.sortIcon} size={17} name='sort-alpha-up-alt' />
                                :
                                null
                            }
        
                        </Text>
                    </TouchableOpacity>
        
                    )
                })
            }
        </View>)
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    columnText: {
        fontFamily: AppFonts.Roboto_Bold,
        fontSize: 17,
        color: AppColor.Dark,
    },
    sortIcon: {
        lineHeight: 17,
        marginLeft: 5
    }
})

export default ListHeader;